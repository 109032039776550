@import '../../node_modules/foundation-sites/scss/foundation';
@include foundation-everything(true);
@import '../../node_modules/toastr/build/toastr.min';
@import '../../node_modules/foundation-datepicker/scss/foundation-datepicker';
@import '../ThirdParty/font-awesome.min';
@import '../ThirdParty/jquery-te-1.4.0.scss';
@import '_uw-style_vars.scss';
@import '_uw-style_foundation.scss';
@import '_uw-theme-main.min.scss';
@import '_uw-theme-fonts.scss';

/*#region Site Styles*/

.display-label {
    font-weight: 700;
    font-size: 1.125rem;
}

.display-value {
    margin-bottom: 1rem;
}

.inline-display .display-label {
    display: inline-block;
    width: 33%;
    margin-bottom: 1rem;
}

.inline-display .display-label::after {
    content: ": ";
}

.inline-display .display-value {
    display: inline-block;
    width: 66%;
}

.phone-type {
    font-weight: normal;
}

/*#endregion*/

/*#region Global fixes*/

@media print, screen and (min-width: 40em) {
    .uw-content-box {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

html {
    height: 100%;
}

#main {
    flex: 1;

    .uw-body {
        p, li, dd, dt {
            font-family: $body-font-family;
        }
    }
}

a.uw-button:focus {
    color: white;
}

a.uw-button.uw-button-inverse:focus {
    color: #1468a0;
}

#uw-top-menus.uw-horizontal .uw-nav-menu.uw-nav-menu-secondary > ul > form > li > a {
    font-weight: 700;
    font-size: 13px;
    font-size: .825rem;
    line-height: 35px;
    line-height: 2.2rem;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

#uw-top-menus.uw-stacked .uw-nav-menu.uw-nav-menu-secondary {
    border-top: 2px solid #cfcfcf;
    position: static;
    top: auto;
    right: auto;
    z-index: auto;
    margin: 0;
    width: 100%;
    display: block;
}

#uw-top-menus.uw-stacked .uw-nav-menu.uw-nav-menu-secondary li {
    float: none;
}

#uw-top-menus .uw-nav-menu.tertiary-menu {
    background-color: #adadad;
    position: static;
    top: auto;
    right: auto;
    z-index: auto;
    margin: 0;
    width: 100%;
    display: block;
}

#uw-top-menus .uw-nav-menu.tertiary-menu svg.uw-caret {
    fill: #494949;
}

#uw-top-menus .uw-nav-menu.tertiary-menu > ul > li {
    float: none;
}

#uw-top-menus .uw-nav-menu.tertiary-menu > ul > li hr {
    color: #494949;
    border-top: 1px solid #494949;
    margin: 0;
}

#uw-top-menus .uw-nav-menu.tertiary-menu > ul > li.uw-dropdown.uw-is-active > ul {
    background-color: #adadad;
}

#uw-top-menus.uw-horizontal .uw-nav-menu.tertiary-menu > ul > li a {
    font-weight: 800;
    font-size: .925rem;
    padding: 1.05rem 0 .1rem;
    margin-bottom: .8rem;
    line-height: 1.25;
    border-bottom: .2rem solid transparent;
}

#uw-top-menus.uw-horizontal .uw-nav-menu.tertiary-menu > ul > li a:hover {
    text-decoration: none;
    border-bottom-color: #494949;
}

#uw-top-menus.uw-stacked .uw-nav-menu.tertiary-menu > ul > li a {
    font-weight: 800;
}

#uw-top-menus .uw-nav-menu.tertiary-menu a {
    color: #494949;
}

#uw-top-menus .uw-nav-menu.tertiary-menu a:hover {
    text-decoration: none;
    border-bottom-color: #494949;
}

#uw-top-menus.uw-stacked .uw-nav-menu.tertiary-menu {
    background-color: #adadad;
    border-top: 0;
}

#main-content {
    max-width: 1300px;
    margin: auto;
}

.row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

label {
    font-size: 1rem;
}

input[type=checkbox] + input[type=hidden] + label {
    display: inline-block;
}

i.has-tip {
    border-bottom: none;
}

.uw-list-tight li {
    margin-bottom: 0;
}

.column > h1, .column > h2, .column > h3, .columns > h1, .columns > h2, .columns > h3 {
    &.uw-mini-bar {
        margin-top: 2.2rem;
    }
}
/*#endregion*/

/*#region Utility Classes*/

.no-gutter-left {
    margin-left: 0;
    padding-left: 0;
}

.no-gutter-right {
    margin-right: 0;
    padding-right: 0;
}

#uw-top-menus .uw-nav-menu li {
    color: #fff;
}

.reveal {
    padding: 0;

    .reveal-header {
        padding: 1rem;
        font-weight: bold;
        border-bottom: 4px solid $uw-red;
        margin-top: 0;
        margin-bottom: 0;
    }

    .reveal-body {
        padding: 1rem;
    }

    .reveal-footer {
        padding: 1rem;
        text-align: right;
    }
}

/*#endregion*/

/*#region custom mobile styles*/

@media only screen and (max-width: 40em) {
    .uw-content-box {
        padding: 1.5rem 1rem;
    }
}

@media only screen and (min-width: 48em) {
    .form-group.responsive-table .row:nth-of-type(even) {
        background-color: #f9f9f9;
    }

    .card .responsive-table .row:nth-of-type(even) {
        background-color: #f0f0f0;
    }
}

@media only screen and (min-width: 40.063em) {
    body.home main {
        padding: 3rem;
    }
}

/*#endregion*/

/*#region toastr*/

#toast-container {
    &.toast-top-right {
        top: 3rem;
        right: 1rem;
    }

    > div.toast {
        opacity: .9;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
        filter: alpha(opacity=90);
        border-radius: 0;
    }

    .toast-error {
        background-color: $uw-red;
    }

    .toast-info {
        background-color: #4763EA;
    }
}

.toast-success {
    background-color: #3AC41E;
}

.toast-warning {
    background-color: #FF8C00;
}

/*#endregion*/

/*#region PageContent*/

.pagecontent-placeholder {
    display: block;
    background-color: aqua;
    cursor: pointer;
}

form .jqte {
    margin-top: 0;
    border: 1px solid #c8c8c8;
    border-radius: 0;
    box-shadow: none;
}

.jqte_toolbar .jqte_tool.jqte_tool_1 .jqte_tool_label {
    height: 25px;
}

form .jqte_editor, form .jqte_source {
    min-height: 200px;
}

.reveal-body form > button {
    margin-bottom: 1rem;
}

/*#endregion*/

/*#region Forms*/

body select {
    padding-right: 1.5rem;
}

.text-danger {
    color: red;
}

.field-validation-error.text-danger {
    display: block;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.validation-summary-valid {
    display: none;

    ul {
        margin-top: 0;
    }
}

.validation-summary-errors {
    color: red;

    ul {
        list-style: none;
        margin-left: 0;

        li {
            margin-bottom: 0;
            border-bottom: none;
            font-weight: normal;
        }
    }
}

.list-items {
    margin-bottom: 1rem;
}

dl {
    dt {
        margin-bottom: 0;

        label {
            font-weight: bold;
        }
    }

    dd {
        margin-bottom: 1rem;
    }
}

div.row.button-row > div > a.uw-button {
    margin: .25rem 0 1rem 0;
}

/*Margins are applied to other inputs in UW-Style, but select and textarea are missing*/
select, textarea {
    margin-bottom: 1rem;
}

.form-group {
    select {
        margin-bottom: 1rem;
    }

    > input.check-box + input[type="hidden"] + label {
        display: inline;
    }
}

.content-template {
    margin-bottom: 1rem;

    .mce-tinymce {
        margin-bottom: 0 !important;
    }
}

.uw-button.float-right + .uw-button.float-right {
    margin-right: .25rem;
}

/*#endregion*/

/*#region isotope list override*/

.three-up .card-list-item:nth-child(3n+3) {
    margin-right: 2%;
}

.three-up .card-list-item {
    width: 31.3333%;
}

ol.card-list.three-up {
    width: 102%;
}

/*#endregion*/

/*#region Component Framework Cards*/

/*Styles for componentless cards*/
.card > .card-body {
    display: block;
    padding: 1rem;
}

[data-role~="component-template"] {
    display: none;
}

[data-role~="component"] .component-view {
    display: block;
}

[data-role~="component"] .component-view .component-body {
    margin-top: 1rem;
}

[data-role~="component"] .component-view .component-body table {
    margin-bottom: 1rem;
}

[data-role~="component"] tr.component-view {
    display: table-row;
}

[data-role~="component"] .component-edit {
    display: none;
}

.component-top-buttons {
    text-align: right;
}

.component-top-buttons a:not(.uw-button) {
    padding-right: 1rem;
    line-height: 2rem;
}

[data-role~="component"] .component-top-buttons button {
    margin-top: 0;
}

[data-role~="component"].component-loading {
    height: 100px;
    color: #fff;
    background-image: url(../Content/Images/icons/loading.gif);
    background-repeat: no-repeat;
    background-position: center;
}

[data-role~="component"].component-loading.edit .component-edit {
    display: none;
}

[data-role~="component"].component-loading .component-view {
    display: none;
}

[data-role~="component"].component-loading .card {
    display: none;
}

[data-role~="component"].component-loading .row {
    display: none;
}

[data-role~="component"].component-loading .uw-content-box {
    display: none;
}

[data-role~="component"].accordion-content {
    padding: 0;
}

[data-role~="component"].accordion-content .component-top-buttons {
    margin-top: -1rem;
}

[data-role~="component"].edit .component-view {
    display: none;
}

[data-role~="component"].edit .component-edit {
    display: block;
}

[data-role~="component"].edit .component-edit .component-body {
    margin-top: 1rem;
}

[data-role~="component"].edit .component-edit .component-body table {
    margin-bottom: 1rem;
}

[data-role~="component"].edit tr.component-edit {
    display: table-row;
}


table tbody[data-role~="component"] tr:nth-child(even) {
    background-color: transparent;
}

table tbody[data-role~="component"]:nth-child(odd) {
    background-color: #f7f7f7;
}

tr.component-edit td {
    padding: 0;
}

.component-list {
    margin: 1rem 0 0 0;
}

.component-list .card {
    margin: 0;
    border-bottom: 0;
}

.component-list > [data-role='component'].edit {
    margin: 1rem 0;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: height ease-in-out .2s;
    border: 1px solid lightgray;
    margin: 1.5rem 0;
    background-color: #F7F7F7;
}

.card .panel {
    background-color: transparent;
}

.card.static-card {
    padding: 1.5rem 1rem;
}

.card .card-header {
    padding: 0 0 5px 15px;
    background-color: transparent;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
    font-size: 1.3rem;

    h1, h2, h3, h4, h5, h6 {
        color: #333333;
        margin: 0;
        font-size: 1.5rem;
    }

    .sub-header{
        font-weight: 100;
        font-size: 1rem;
    }

    &.card-header-list{
        font-weight: 100;
        font-size: 1rem;
    }
}

.card .card-subheader {
    padding: 0 0 5px 15px;
    background-color: transparent;
    border-top: 2px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
    font-size: 1.2rem;

    h1, h2, h3, h4, h5, h6 {
        color: #333333;
        margin: 0;
        font-size: 1.5rem;
    }
}

.card .card-callout {
    font-weight: bold;
    font-size: 1.1rem;
}

.card .card-body {
    .card-header {
        margin: 0 -1rem 1rem;
        padding-bottom: 1rem;
        line-height: 2rem;
    }

    .card-subheader {
        margin: 1rem -1rem 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        line-height: 2rem;
    }
}


.card hr {
    border-top: 1px solid #ddd;
}


.card > .component-view {
    padding: 1rem;

    fieldset {
        margin-bottom: 0;
    }
}



.card > .component-view > .component-top-buttons {
    float: right;
}

.card > .component-edit {
    padding: 1rem;
}

.card .card {
    background-color: white;
}

.no-results {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid lightgray;
}

.card h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-weight: normal;
    font-size: 1.1rem;
}

.card h4 {
    margin-top: 0;
    margin-bottom: 0;
}

.card .card .card-body .card-header {
    font-size: 1.1rem;
}

.card h3.url-header {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: normal;
    font-size: 1.1rem;
    font-weight: bold;
}

.card .url-subheader {
    font-size: 1rem;
    font-weight: normal;
    margin-top: -.5rem;
    margin-bottom: .5rem;
}

.search-input[type=text] {
    height: 2.4rem;
    background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5NTEgMTAyNCI+PHBhdGggY2xhc3M9InBhdGgxIiBkPSJNNjU4LjI4NiA0NzUuNDNxMC0xMDUuNzE1LTc1LjE0My0xODAuODU4VDQwMi4yODYgMjE5LjQzIDIyMS40MyAyOTQuNTcgMTQ2LjI4NSA0NzUuNDN0NzUuMTQzIDE4MC44NTYgMTgwLjg1NiA3NS4xNDMgMTgwLjg1Ny03NS4xNDQgNzUuMTQzLTE4MC44NTd6bTI5Mi41NyA0NzUuNDI3cTAgMjkuNzE0LTIxLjcxMyA1MS40M1Q4NzcuNzEzIDEwMjRxLTMwLjg1NiAwLTUxLjQyOC0yMS43MTRsLTE5Ni0xOTUuNDNxLTEwMi4yODYgNzAuODU4LTIyOCA3MC44NThRMzIwLjU3IDg3Ny43MTQgMjQ2IDg0NnQtMTI4LjU3Mi04NS43MTQtODUuNzE0LTEyOC41N1QwIDQ3NS40Mjh0MzEuNzE0LTE1Ni4yODcgODUuNzE0LTEyOC41NyAxMjguNTctODUuNzE1IDE1Ni4yODctMzEuNzE0IDE1Ni4yODYgMzEuNzE0IDEyOC41NzIgODUuNzE0IDg1LjcxNCAxMjguNTdUODA0LjU3IDQ3NS40M3EwIDEyNS43MTMtNzAuODU3IDIyOGwxOTYgMTk2cTIxLjE0MyAyMS4xNDIgMjEuMTQzIDUxLjQyOHoiIGZpbGw9IiM4ZThlOTIiIHRyYW5zZm9ybT0ic2NhbGUoMC41KSIgLz48L3N2Zz4=) .5rem .6rem no-repeat #fff;
    padding-left: 2rem;
    background-size: 32px;
}

.uw-content-box table.details-table {
    margin-bottom: 1rem;
}

.details-table tr th {
    padding: .5rem;
}

.details-table tr td {
    font-size: 1rem;
    padding: .5rem;
}

div.row .pagination {
    margin-top: 2rem;

    a {
        display: inline;
        background: initial;
        color: #0479a8;
        text-decoration: none;
        padding: 1rem;
    }
}

/*#endregion*/

/*#region Person Cards*/

.avatar, .avatar:first-child {
    float: left;
}

.avatar--small {
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.avatar--large {
    width: 100%;
}

.avatar--default {
    background-color: rgb(52, 73, 94);
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
}

.person-info {
    margin-left: 4.5rem;
    min-height: 4rem;
}

.person-info > h4 {
    margin: 1.8rem 0;
}

.person-info > h3 {
    margin: 1.8rem 0;
}

.person-info .name {
    font-weight: bold;
}

.person-info > * {
    margin-bottom: 0;
}

.person-info .fa {
    margin-right: 0.5rem;
}

/*#endregion*/

/*#region Component Framework Grids*/

th[data-role="sort-action"] {
    cursor: pointer;
    color: #0479a8;
}

th[data-role="sort-action"].desc::after, th[data-role="sort-icon"].desc::after {
    font-family: 'FontAwesome';
    content: "\f0d7";
    margin-left: 5px;
    vertical-align: bottom;
    color: #0479a8;
}

th[data-role="sort-action"].asc::after, th[data-role="sort-icon"].asc::after {
    font-family: 'FontAwesome';
    content: "\f0d8";
    margin-left: 5px;
    vertical-align: bottom;
    color: #0479a8;
}

th[data-role="sort-action"] a {
    text-decoration: underline;
}

table.details-table tbody:nth-child(even) tr {
    background-color: #e4e4e4;
}

table.details-table tbody tr:nth-child(even) {
    background-color: transparent;
}

table.details-table tbody tr[data-toggle="collapse"] + tr[data-toggle="target"] {
    background-color: #cacfd1;
}

/*#endregion*/

/*#region Component Framework Vis */

a[data-role="update-setting-click"] {
    cursor: pointer;
}

a.active[data-role="update-setting-click"] {
    color: #282728;
}

.vis-tooltip {
    position: absolute;
    left: 100px;
    top: 300px;
    width: 300px;
    z-index: 1338;
    padding: .5rem;
    pointer-events: none;
}

.vis-tooltip.card .card-header {
    font-size: 1rem;
    padding: 0;
}

.vis-tooltip table tbody, .vis-tooltip table tbody tr, .vis-tooltip table tbody tr:nth-child(even) {
    background-color: transparent;
}

.vis-tooltip table tbody tr + tr td {
    border-top: 1px solid #EDEDED;
}

.vis-tooltip table tr td {
    line-height: .7rem;
    font-size: 1rem;
    padding: .5rem;
}

.vis-tooltip .arrow {
    position: absolute;
    bottom: -9px;
    left: 141px;
    width: 17px;
    height: 9px;
    background: url(/Content/Images/arrow.png) no-repeat;
}

.vis-crosshair {
    stroke: #BBB;
}

.vis {
    text-align: center;
}

.vis.vis-loading {
    height: 100px;
    color: #fff;
    background-image: url(/Content/Images/icons/loading.gif);
    background-repeat: no-repeat;
    background-position: center;
}

/*#endregion */

/*#region Component Framework FileUploader */

.fileuploader-hide {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.field-validation-error.text-danger.fileuploader-validation {
    margin-top: 10px;
}

.qq-uploader-selector {
    overflow-x: hidden;
    min-height: 100px;
    max-height: none;
    padding: 7px;
}

.uw-button.qq-hide {
    display: none;
}
/*.qq-upload-button-selector{
    margin-bottom:7px;
}*/
.qq-upload-list li.qq-upload-success {
    background-color: transparent;
    border-bottom: none;
}

[data-role~="file-Upload"] {
    margin-bottom: 10px;
}

.fileuploader-view-image {
    max-height: 75px;
}

.fileuploader-view-wrapper {
}

.fileuploader-view-link {
    display: inline-block;
    padding-top: 40px;
    padding-left: 10px;
}

.qq-upload-file {
    width: auto;
}

.qq-upload-list-selector .uw-button {
    margin-top: 14.5px;
}
/*#endregion */
